// src/pages/404.js
import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function NotFound() {
  return (
    <Layout>
      <Seo title="404 Not Found" description="ページが見つかりません" />
      <div className="text-center mx-auto my-16">
        <h1 className="text-3xl py-10">404 Not Found</h1>
        <Link to="/">トップページへ</Link>
      </div>
    </Layout>
  )
}
